import React, { useState } from "react";
import { Formik, Form } from "formik";
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/map';
import * as yup from 'yup';
import "yup-phone";
import tw, { styled } from "twin.macro";
import {
  H2,
  clipPath,
  FormField,
  FormError,
  FormLabel,
  FormCheckbox,
  SolidButton,
  soloBorder,
  BorderButton,
} from "../utils/helpers";
import { track } from "../analytics";

const validationSchema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email("Enter a valid email").required("Required"),
  phone_number: yup.string()
    .phone("US")
    .required("Please enter a valid US phone number."),
  zip_code: yup.string()
    .matches(/^[0-9]{5}$/, "Please use a valid 5-digit zip")
    .required("Required"),
});

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  zip_code: "",
  success: false,
};

const FormWrapper = styled.div`
  ${tw`relative text-center text-white max-w-2xl mx-auto bg-ink px-6 py-12 md:p-12 z-20`}
  clip-path: ${clipPath.bevels};
  &:after {
    content: "";
    ${tw`absolute pointer-events-none z-0 top-3 right-3 left-3 bottom-3`}
    ${soloBorder}
  }
`;

const FormHeader = tw(H2)`
  text-2xl md:text-4xl lg:text-4xl
`;

const FormElement = tw.div`
  relative flex flex-col max-w-lg mx-auto mb-8 z-20
`;

const ConsentBox = tw(FormElement)`
  flex-row
`;

const SubmitButton = SolidButton.withComponent("button");

const OfferForm = ({ header, cta, uid, trackPage, trackLocation, light }) => {
  const [consent, setConsent] = useState(false);

  const handleConsent = () => {
    setConsent((constent) => !consent);
  };

  const handleSubmit = async (values) => {
    console.log('Submitting')

    const { first_name, last_name, email, phone_number, zip_code } = values;

    const response = await fetch("https://api.jeevz.com/leads", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer 71bd81746946d10d8d0bc89063b707241355d44344a75057d883d879d4cd9ec8bde8722f8ae66c3dcee937d40255f8cafaa6799a2ebb7f68607943b0d8dfd007"
      },
      // mode: "no-cors",
      body: JSON.stringify({
        website_visit: true,
        client: {
          phone_number: phone_number,
          name: `${first_name} ${last_name}`,
          email: email,
          zip_code: zip_code,
          lead_source: `offer_form_${uid}`,
        },
      }),
    });

    if (response.status === 200) {
      initialValues.success = true;
      track(`Offer form submitted - ${uid}`, {
        page: trackPage,
        location: trackLocation,
        cta: cta || "none",
      });
      window.location = "https://jeevz.com"
    } else {
      const json = await response.json();
      let message = json?.data?.attributes?.messages
      if (message) { message = message[0] }

      this.setState({
        error: message || "Something went wrong.",
        submitting: false,
      });
    }
  };

  return (
    <FormWrapper>
      <FormHeader>{header}</FormHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form
            name={`${uid}-offer-form`}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            method="post"
          >
            <FormElement>
              <FormLabel htmlFor="first_name">First name</FormLabel>
              <FormField type="text" name="first_name" />
              <FormError name="first_name" component="div" />
            </FormElement>

            <FormElement>
              <FormLabel htmlFor="last_name">Last name</FormLabel>
              <FormField type="text" name="last_name" />
              <FormError name="last_name" component="div" />
            </FormElement>

            <FormElement>
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormField type="email" name="email" />
              <FormError name="email" component="div" />
            </FormElement>

            <FormElement>
              <FormLabel htmlFor="phone_number">Phone number</FormLabel>
              <FormField type="text" name="phone_number" />
              <FormError name="phone_number" component="div" />
            </FormElement>

            <FormElement>
              <FormLabel htmlFor="zip_code">Zipcode</FormLabel>
              <FormField type="text" name="zip_code" />
              <FormError name="zip_code" component="div" />
            </FormElement>

            <ConsentBox onClick={handleConsent}>
              <FormCheckbox type="checkbox" name="consent" checked={consent} />
              <FormLabel htmlFor="consent">
                I agree to JEEVZ's Privacy Policy, including our use of cookies.
              </FormLabel>
            </ConsentBox>

            <SubmitButton
              aria-label={initialValues.success ? "Success!" : "Submit form"}
              disabled={!consent || isSubmitting || initialValues.success}
              notActive={!consent || isSubmitting || initialValues.success}
              white
              type="submit"
            >
              { initialValues.success ? "Success!" : (cta ? cta : `Submit`) }
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default OfferForm;
