import React from "react";
import tw from "twin.macro";
import { H2, P } from "../utils/helpers";
import { RichText } from "prismic-reactjs";
import { htmlSerializer } from "../utils/htmlSerializer";
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql";
import Notch from "../img/car-notch.svg";

const CarsContent = tw.div`
  w-full max-w-4xl lg:max-w-5xl mx-auto text-white text-center px-4 md:px-8
`;

const CarsBG = tw.div`
  block relative inset-y-0 h-full w-full flex-none py-20 overflow-hidden bg-ink
`;

const CarGrid = tw.div`
  sm:(grid gap-x-10 gap-y-16 grid-cols-2 px-4) mt-20
`;

const CarTile = tw.div`
  block flex flex-row text-left items-start mb-12 sm:mb-0 pl-3 md:p-0
`;

const CarThumb = tw.div`
  relative lg:w-40 h-24 lg:h-32 flex-none flex flex-col items-center mb-2
`;

const CarImg = tw.img`
  relative mx-auto z-20 top-6 lg:top-4 right-4 lg:right-8 w-28 lg:w-auto
`;

const NotchBG = tw(Notch)`
  absolute inset-y-0 -mt-4 right-2 z-10 w-24 lg:w-32
`;

const CarInfo = tw.div`
  pl-4
`;

const Cars = ({ cars_intro, cars_title, cars }) => (
  <CarsBG>
    <CarsContent>
      <H2>{RichText.asText(cars_title)}</H2>
      <RichText
        render={cars_intro}
        htmlSerializer={htmlSerializer}
        linkResolver={linkResolver}
      />
      <CarGrid>
        {cars.map(({ name, thumbnail, description }, idx) => (
          <CarTile>
            <CarThumb>
              <NotchBG />
              <CarImg src={thumbnail.url} alt={name} />
            </CarThumb>
            <CarInfo>
              <strong>{name}</strong>
              <P>{description}</P>
            </CarInfo>
          </CarTile>
        ))}
      </CarGrid>
    </CarsContent>
  </CarsBG>
);

export default Cars;
