import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../layouts/layout";
import { RichText } from "prismic-reactjs";
import SEO from "../components/Seo";
import tw from "twin.macro";
import Navigation from "../components/Navigation";
import Cars from "../components/Cars";
import FeatureStrip from "../components/slices/FeatureStrip";
import OfferForm from "../components/OfferForm";
import { H2, BGOverlay, BGImageFluid } from "../utils/helpers";
import { htmlSerializer } from "../utils/htmlSerializer";
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql";

const OfferBG = tw.div`
  block relative inset-y-0 h-full w-full flex-none pb-8 pt-28 px-4 md:pt-36 md:px-8 lg:pt-48 lg:pb-28 overflow-hidden bg-gray-900
`;

const OfferContent = tw.div`
  relative w-full max-w-3xl mx-auto text-white text-center z-20
`;

const OfferPage = ({ data }) => {
  const page = get(data, "prismic.allOffer_pages.edges[0].node");
  if (!page) return null;
  console.log(page);
  const featureSlice = { fields: page.feature_strip };
  return (
    <Layout>
      <SEO title={RichText.asText(page.title)} />
      <OfferBG>
        <BGOverlay />
        {page.background_image !== null && (
          <BGImageFluid
            fluid={page.background_imageSharp.childImageSharp.fluid}
            style={{ position: "absolute" }}
          />
        )}
        <Navigation />
        <OfferContent>
          <H2>{RichText.asText(page.title)}</H2>
          <RichText
            render={page.page_intro}
            htmlSerializer={htmlSerializer}
            linkResolver={linkResolver}
          />
        </OfferContent>
        <FeatureStrip slice={featureSlice} white={true} />
        <OfferForm
          header={
            typeof page.form_title === "object"
              ? RichText.asText(page.form_title)
              : page.form_title
          }
          cta={page.form_cta}
          uid={page._meta.uid}
        />
      </OfferBG>
      {page.cars_title[0].text !== "" && (
        <Cars
          cars={page.cars}
          cars_title={page.cars_title}
          cars_intro={page.cars_intro}
        />
      )}
    </Layout>
  );
};

export default OfferPage;

export const query = graphql`
  query OfferPageQuery($uid: String!) {
    prismic {
      allOffer_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            feature_strip {
              feature_title: feature_header
              feature_icon
              feature_description: feature_text
            }
            background_image
            background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1440, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            form_cta
            form_title
            page_intro
            title: page_title
            cars_title
            cars_intro
            cars {
              name: car_name
              description: car_description
              thumbnail: car_thumbnail
            }
          }
        }
      }
    }
  }
`;
